'use client';

import React, { ReactNode } from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import investorClient from '@app/services/investor/investorClient';
import { ApolloProvider } from '@apollo/client';
import { AuthContextProvider } from './AuthContextProvider';
import { UserContextProvider } from './UserContextProvider';
import { Web3Modal } from './Web3Modal';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { appConfig } from '@app/config';

type GeneralContextProviderProps = {
  children: ReactNode;
};

export const GeneralContextProvider: React.FC<GeneralContextProviderProps> = ({
  children,
}) => {
  return (
    <ApolloProvider client={investorClient}>
      <Web3Modal>
        <LazyMotion features={domAnimation}>
          <AuthContextProvider>
            <UserContextProvider>{children}</UserContextProvider>
          </AuthContextProvider>
        </LazyMotion>
      </Web3Modal>
      <TawkMessengerReact
        propertyId={appConfig.tawkPropertyId}
        widgetId={appConfig.tawkWidgetId}
        customStyle={{ zIndex: 5 }}
      />
    </ApolloProvider>
  );
};
